@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Custom animation classes */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideInFromBottom {
  from { transform: translateY(20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
}

.animate-fade-in {
  animation: fadeIn 0.8s ease-out forwards;
}

.animate-slide-in {
  animation: slideInFromBottom 0.8s ease-out forwards;
}

.animate-pulse-slow {
  animation: pulse 3s infinite ease-in-out;
}

/* Delayed animations */
.delay-100 {
  animation-delay: 100ms;
}

.delay-200 {
  animation-delay: 200ms;
}

.delay-300 {
  animation-delay: 300ms;
}

.delay-500 {
  animation-delay: 500ms;
}

/* Hover effects */
.hover-grow {
  transition: transform 0.3s ease;
}

.hover-grow:hover {
  transform: scale(1.03);
}

/* Technology-themed effects */
.tech-glow {
  box-shadow: 0 0 20px rgba(59, 130, 246, 0.3);
  transition: box-shadow 0.3s ease;
}

.tech-glow:hover {
  box-shadow: 0 0 30px rgba(59, 130, 246, 0.5);
}

.tech-border {
  position: relative;
  overflow: hidden;
}

.tech-border::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(to right, transparent, #3b82f6, transparent);
  animation: techBorderAnim 3s linear infinite;
}

@keyframes techBorderAnim {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}

/* Gradient text */
.text-gradient {
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  background-image: linear-gradient(to right, #3b82f6, #1e40af);
}

/* Grid patterns */
.tech-grid-bg {
  background-image: 
    linear-gradient(rgba(17, 24, 39, 0.97) 1px, transparent 1px),
    linear-gradient(90deg, rgba(17, 24, 39, 0.97) 1px, transparent 1px);
  background-size: 20px 20px;
  background-position: -1px -1px;
} 